@import 'css/variables';

@import 'snow-theme';
@import 'ql-toolbar';
@import 'placeholder-for-loading-image';

.quill{
  position: relative;
  &.-readOnly{
    cursor: default;
    a, input {
      cursor: pointer;
    }
  }
  > .ql-toolbar{
    height: 30px;
    overflow-y: scroll;
  }
  > .ql-container > .ql-editor{
    min-height: 70px;
    font-size: 17px;

    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;

    font-family: 'Open Sans';

    // color: rgb(206, 199, 245);
    // We need !important-s because we have to override styles copypasted by users
    code, span, em, strong, b, a, sup, sub, blockquote, mark, p, ul, ol, u{
      color: rgb(238, 236, 246) !important;
      background: rgba(0, 0, 0, 0) !important;
    }

    strong{
      font-family: inherit;
      font-weight: 100;
      color: $green !important;
    }
    em{
      color: rgb(171, 127, 242) !important;
      font-style: normal;
    }
    a{
      text-decoration: underline;
    }

    pre.ql-syntax, code{
      background: rgba(183, 225, 246, 0.06) !important;
      color: rgb(167, 209, 219) !important;
    }
    pre.ql-syntax, code{
      font-size: 0.95em;
      white-space: pre-wrap;
      border-radius: 3px;
    }
    pre.ql-syntax{
      margin: 5px 0;
      padding: 5px 10px;
    }
    code{
      padding: 2px 4px;
    }

    sup, sub{
      font-size: 0.7em;
    }
    ul, ol{
      list-style-type: initial;
      // li{
      //   padding-left: 0;
      // }
    }
    ul{ padding-left: 15px; }
    ol{ padding-left: 21px; }
    blockquote{
      font-size: 14px;
      border-left: 1px solid rgba(206, 199, 245, 0.62);
      color: rgb(238, 236, 246) !important;
      background: rgba(21, 23, 45, 0.38) !important;
      padding: 4px 16px;
    }

    img{
      max-width: 100%;
      border-radius: 2px;
    }
  }
  // placeholder!
  > .ql-container > .ql-editor.ql-blank::before{
    font-size: 15px;
    font-style: normal;
    padding-right: 10px;
    line-height: 20px;
    color: rgb(172, 181, 218);
  }
}
