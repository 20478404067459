@import 'css/variables';

.standard-title-and-description{
  > h1.title, h2.title{
    margin: 20px 0;
    text-align: center;
    font-weight: 300;
    color: $green;
    font-size: 27px;
  }
  > article.description{
    color: white;
    font-weight: 100;
    font-size: 15px;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    line-height: 20px;
  }
}
