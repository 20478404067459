:local(.li){
  display: flex;
  align-items: center;
  cursor: pointer;

  img{
    flex-grow: 0;
    flex-shrink: 0;
    width: 30px;
    border-radius: 2px;
  }
  .right{
    margin-left: 10px;
    color: rgb(81, 83, 114);
    .name{
      font-style: 13px;
    }
  }
}
