// temporary, don't know if I should reuse it or use something else
.standard-dropdown-with-arrow{
  position: absolute;
  z-index: 800;
  border-radius: 0 0 4px 4px;
  filter: alpha(opacity=0);

  &:after, &:before{
    position: absolute;
    pointer-events: none;
    border: solid rgba(0, 0, 0, 0);
    content: '';
    height: 0; width: 0;
    bottom: 100%;
    right: 23px;
  }
  &:before{
    border-width: 6px;
    margin: 0 -6px;
    border-bottom-color: rgb(26, 29, 57);
  }
  &:after{
    border-width: 5px;
    margin: 0 -5px;
    border-bottom-color: rgb(26, 29, 57);
  }
}
