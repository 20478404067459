@import 'css/variables';

:local(.main){
  // will be moved to `.standard-` at some point
  > .container{
    display: flex;
    margin-top: 30px;
    > nav.navigation-admin{
      flex-grow: 0;
      flex-shrink: 0;
      width: 250px;
      min-height: 400px;
      margin-right: 40px;
      margin-bottom: 60px;
      background: rgba(34, 38, 74, 0.27);
      a{
        display: block;
        padding: 10px;
        font-size: 14px;
        color: $purple;
        border-bottom: 1px solid rgba(68, 67, 67, 0.07);
        opacity: 0.4;
        &.active{
          opacity: 1;
        }
      }
    }
    > section.actual-main{
      flex-grow: 1;
      flex-shrink: 1;
      padding-bottom: 30px;
      > h1.standard-admin-title{
        font-size: 30px;
      }
      > div.standard-admin-sections{
        margin-top: 20px;
        > section.standard-admin-section{
          margin-top: 30px;
          > h2.standard-admin-section-title{
            background: rgba(68, 67, 67, 0.07);
            padding: 15px 20px;
            padding-top: 11px;
            margin-bottom: 20px;
            font-size: 12px;
            text-transform: uppercase;
            border-top: 4px solid rgba(177, 177, 177, 0.42);
            font-weight: lighter;
          }
        }
      }
    }
  } 
}
