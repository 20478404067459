@import 'css/variables';

:local(.toggler){
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  width: 35px;
  margin-right: 10px;
  position: relative;

  &:hover, &:focus, &[aria-expanded=true]{
    background: rgba(32, 35, 73, 0.55);
    border-radius: 3px;
  }
  > div.amount-of-unread-notifications,
  &.-there-are-unread-notifications i {
    color: white;
  }

  &.-there-are-unread-notifications{
    i{
      animation: shake 8s 1s ease-in-out infinite;
      transform-origin: 50% 4px;
    }
  }
  &.-there-are-no-unread-notifications{
    i{ color: rgb(216, 221, 239); }
  }

  i{
    font-size: 17px;
  }
  > div.amount-of-unread-notifications{
    position: absolute;
    top: 0; right: 0;
    border-radius: 50%;

    width: 15px; height: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
    // text-shadow: 0px 0px 7px rgba(255, 255, 255, 0.5);
    font-weight: bold;
  }
}

:local(.dropdown){
  .extendable-class-read-all_and_see-more{
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 9px;
    cursor: pointer;
  }

  > div.header{
    display: flex;
    justify-content: space-between;
    height: 30px;
    border-bottom: 1px solid rgb(39, 43, 86);
    > div.title{
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 9px;
      display: flex;
      height: 100%;
      padding: 0 15px;
      align-items: center;
      cursor: default;
    }
    > button.read-all-button{
      height: 100%;
      padding: 0 15px;
      color: rgb(240, 122, 62);
      font-weight: 400;
      &:hover{
        opacity: 0.8;
      }
      @extend .extendable-class-read-all_and_see-more;
    }
  }
  > div.footer{
    padding: 10px 0;
    text-align: center;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid rgba(135, 128, 190, 0.15);

    &:hover{
      background: rgb(48, 49, 90);
    }

    @extend .extendable-class-read-all_and_see-more;
  }
  > ul.notifications{
    max-height: 400px;
    overflow-y: scroll;
    &.-no-footer{
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}



@keyframes shake {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}