@import 'css/variables';

:local(.progressBar){
  padding: 1px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.09) !important;
  // box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  // box-shadow: 0px 0px 6px 1px rgba(137, 94, 241, 0.62);

  > div.inner{
    background-color: #70eca0;

    height: 11px;
    border-radius: 3px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    box-shadow: 0px 0px 6px 1px rgba(136, 125, 220, 0.33);
    transition: width 400ms ease-in-out;
  } 
}
