:local(.main){
  .wrapper{
    display: flex;
    align-items: flex-start;
    .user, .skills, .stats{
      padding: 15px;
      background: rgb(24, 28, 57);
      border-radius: 4px;
      box-shadow: 0 1px 0 0 rgb(33, 37, 67), 0 0 0 1px rgb(32, 38, 71);
      min-height: 328px;

      h1{
        margin-bottom: 20px;
      }
    }

    .user{
      flex-grow: 0;
      flex-shrink: 0;
      width: 200px;

      img{
        border-radius: 2px;
        width: 100%;
      }

      .username{
        margin-top: 10px;
      }

      .email, .created-at{
        color: rgb(144, 138, 186);
      }

      .email, .created-at{
        font-size: 12px;
        margin-top: 8px;
      }
    }

    .skills{
      margin-left: 40px;
      width: 460px;
      flex-shrink: 0;

      padding: 20px;
      padding-bottom: 0;

      h2{
        padding-bottom: 5px;
        font-size: 14px;
        padding-left: 1px;
      }

      .skill{
        margin-bottom: 17px;
        cursor: pointer;
        &:hover{
          div.inner{
            background-color: #70eca0;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
            box-shadow: 0px 0px 6px 1px rgba(136, 125, 220, 0.33);
          }

          span.n-of-flashcards{
            opacity: 1;
          }
        }
      }

      .progress-bar{
        width: 100%;
        padding: 1px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.09) !important;

        position: relative;
        span.n-of-flashcards{
          opacity: 1;
          font-size: 12px;
          position: absolute;
          right: 5px;
          top: -19px;
        }

        > div.inner{
          transition: width 400ms ease-in-out;

          background-color: #70eca0c2;
          height: 11px;
          border-radius: 3px;
          background-image: linear-gradient(to bottom, rgba(89, 208, 86, 0.3), rgba(17, 149, 19, 0.05));
          box-shadow: none;
        } 
      }
    }

    .stats{
      margin-left: 40px;
      flex-grow: 1;

      padding: 20px;

      position: relative;
      .settings-button{
        position: absolute;
        right: 0px; top: 0px;

        min-width: 0;
        min-height: 0;
        padding: 8px 11px;
        font-size: 9px;
      }

      ul.textual-stats{
        font-size: 14px;
        padding-left: 20px;
        li.stat{
          padding-bottom: 17px;
          ul.memory-levels{
            padding-left: 10px;
            li{
              padding-top: 8px;
            } 
            div.square{
              width: 12px; height: 12px;
              border-radius: 3px;
              border-radius: 2px;
              display: inline-block;
              margin-right: 7px;
              border: 1px solid white;
            }
          }
        }
      }

    }
  }

  .created-courses{
    margin-top: 40px;
    h1{
      margin-bottom: 30px;
    }
  }

  @media(max-width: 800px){
    .created-courses{
      h1{
        padding-left: 0 !important;
      }
      .standard-navigation_and_courses{
        padding: 0;
      }
    }
    .wrapper{
      display: block;
      .skills, .user, .stats{
        min-height: 0;
      }
      .skills{
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        padding-bottom: 10px;
      }
      .stats{ display: none; }
      .user{
        width: auto;
        text-align: center;
        img{
          max-width: 115px;
        }
      }
      .skills{
        h1{ text-align: center; }
      }
    }
  }
}
