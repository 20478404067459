@import 'css/variables';

:local(.standardTooltipButton){
  display: inline-block;

  position: relative;
  top: -2px;
  padding-bottom: 2px;

  cursor: pointer;
  > i{
    color: $purple;
    opacity: 0.9;
    font-size: 14px;
  }
}
