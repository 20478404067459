@import 'css/variables';

@import url('https://fonts.googleapis.com/css?family=Libre+Franklin|Montserrat:100,200,300,400,500,600,700&display=swap');

:local(main.main){
  header .sign-in-links{
    background: none !important;
  }
  header{
    background: rgb(23, 22, 37) !important;
  }

  // For github-ribbon
  position: relative;
  overflow: hidden;
  header{
    position: relative;
    z-index: 1;
  }
  .github-ribbon{
    &:hover{
      background: rgb(250, 92, 18);
    }
    z-index: 0;
    top: 97px;
    right: -67px;
    display: block;
    position: absolute;
    width: 282px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $orange;
    font-family: "Sentinel SSm A", "Sentinel SSm B", "Sentinel", "Georgia", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
  }

  @media(max-width: 930px){
    .github-ribbon{
      display: none;
    }
  }

  .feature-list{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .feature-box{
      width: 47%;
      display: flex;
      max-width: 560px;
      align-items: flex-start;
      padding: 20px;
      background: rgb(14, 16, 34);
      border-radius: 10px;
      margin-bottom: 20px;

      .image{
        width: 50px;
        margin-right: 40px;
        img{
          max-width: 100%;
        }

        span, i{
          color: white; // !important;
          font-size: 46px;
          text-shadow: 0 0 10px rgba(136, 125, 220, 0.5), 0 0 20px rgba(136, 125, 220, 0.2), 0 0 30px rgba(136, 125, 220, 0.2), 0 0 40px rgba(136, 125, 220, 0.2), 0 0 50px rgba(136, 125, 220, 0.2), 0 0 60px rgba(136, 125, 220, 0.2), 0 0 70px rgba(136, 125, 220, 0.2) !important;
          // text-shadow: none !important;
        }

      }
      h5{
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 2px;
        margin: 0;
      }
      p{
        line-height: 23px;
      }

      &.shortcuts{
        i, h5{
          color: rgb(69, 120, 204);
        }
        i{
          text-shadow: 0 0 10px rgba(99, 82, 234, 0.28), 0 0 20px rgba(136, 125, 220, 0.2), 0 0 30px rgba(136, 125, 220, 0.3), 0 0 40px rgba(136, 125, 220, 0.5), 0 0 50px rgba(70, 48, 236, 0.5), 0 0 60px rgba(59, 38, 215, 0.5), 0 0 70px rgba(67, 45, 232, 0.5);
        }
      }

      &.formulas{
        i, h5{
          color: rgb(99, 246, 232);
        }
      }

      &.gifs{
        span, h5{
          color: rgb(194, 101, 118);
        }
        span{
          text-shadow: 0 0 10px rgba(238, 84, 84, 0.58), 0 0 70px #ee5454;
        }
      }

      &.creations{
        span, h5{ color: rgb(133, 210, 135); }
      }

      &.comfortable{
        span{
          text-shadow: 0 0 10px rgba(55, 160, 221, 0.36), 0 0 20px rgba(55, 160, 221, 0.27), 0 0 30px rgb(44, 122, 168), 0 0 40px rgb(56, 119, 156), 0 0 50px rgba(55, 160, 221, 0.55), 0 0 60px rgb(31, 52, 82), 0 0 70px rgb(32, 65, 97);
        }
        span, h5{
          color: rgb(55, 160, 221);
        }
      }

      &.free{
        span, h5{
          color: rgb(133, 210, 135);
        }
      }

      &.open-source{
        i, h5{
          color: rgb(201, 137, 250);
        }
        i{
          position: relative;
          left: 10px;
        }
        a{
          color: rgb(239, 117, 248);
          text-decoration: underline;
        }
      }

      &.algorithm{
        span, h5{
          color: rgb(250, 97, 210);
        }
      }

    }

  }
  
  .standard-article-formatting{
    max-width: 1300px;
    padding-bottom: 200px;
    padding-top: 130px;
    h2.section-heading{
      margin-bottom: 75px;
      margin-top: 170px;
      font-size: 48px;
      text-align: center;
    }
    div.section-explanation{
      font-size: 20px;
      text-align: center;
      max-width: 700px;
      margin: 0 auto;
      margin-top: -36px;
    }
    a{
      text-decoration: none;
    }
    section.article-section{
      padding-top: 60px;
    }
  }

  section.article-headings{
    text-align: center;
    margin-bottom: 170px;
    h1{
      font-size: 60px;
      line-height: 86px;
    }
    h2.subtitle{
      margin: 0;
      margin-bottom: 100px;
      margin-top: 35px;
      // color: rgb(201, 195, 246);
    }
    h2.more{
      line-height: 34px;
      margin-top: 50px;
    }
    .button{
      display: inline-flex;
      margin: 0 auto;
      font-size: 15px;
      margin-top: 120px;
    }
  }

  section.two-types-of-flashcards{
    ol{
      display: flex;

      li{
        width: 50%;
        border-radius: 5px;
        background: rgba(136, 125, 220, 0.15);

        section.problem{
          min-height: 150px;
          background: none;
          box-shadow: none;
          margin: 50px 7px;
          .first-column{ padding-right: 7px; }
          .second-column{ padding-left: 7px; }
        }

        display: flex;
        flex-direction: column;
        section.problem{
          flex-grow: 1;
        }
        div.instruction{
          flex-grow: 0;
        }

        div.instruction.-top{
          padding: 10px 20px;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          // color: $purple;
          font-weight: 600;
          font-family: 'Libre Franklin', sans-serif;
          font-size: 18px;
        }

        div.instruction.-bottom{
          padding: 10px 20px;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
      li.separate{
        margin-right: 10px;
        div.instruction.-top{ background: rgb(55, 51, 94); }
        div.instruction.-bottom{ background: rgba(55, 51, 94, 0.5); }
      }
      li.inline{
        margin-left: 10px;
        div.instruction.-top{ background: rgb(176, 74, 13); }
        div.instruction.-bottom{ background: rgba(55, 51, 94, 0.5); }

        input.answer-input{
          cursor: pointer !important;
          &:hover{
            background: rgba(136, 125, 220, 0.16);
          }
        }
      }
    }

    button.reset-button{
      margin: 0 auto;
      margin-top: 40px;
      border-radius: 2px;
    }

  }

  section.creation{
    .button.-orange{
      font-size: 15px;
      margin: 0 auto;
      max-width: 300px;
      margin-top: 150px;
    }

    .how-to-create{
      display: none;
    }
    section.problem{
      margin-top: 100px;
      .ql-editor:focus{
        background: rgb(40, 45, 88);
      }
      .quill > .ql-container > .ql-editor{
        min-height: 63px;
      }

      .quill{
        height: 100%;
      }

      &.-withInlinedAnswers{
        > .first-column{
          .ql-toolbar{
            .ql-formats{ margin-right: 9px !important; }
            .ql-formats:first-child{ margin-right: 2px !important; }
            padding-right: 0;
            button.ql-answer{
              font-size: 10px;
              width: 50px !important;
              padding: 0;
              text-align: center;
              border-radius: 2px;
              line-height: 10px;
              border: 1px solid rgb(44, 225, 83);
              color: rgb(166, 241, 182);
              font-weight: 300;
              &.ql-active, &:hover{
                // box-shadow: 0px 0px 6px 1px rgba(74, 241, 42, 0.39);
                text-shadow: 0 0 1px rgba(243, 243, 243, 0.5);
              }
              &::after{
                content: "Mark As Answer";
              }
            }
          }
        }
      }
      .ql-toolbar{}
    }
    .new-problem{
      position: relative;
      max-width: 970px;
      margin: 0 auto;
      margin-top: 25px;
      padding: 0 8px;

      .ql-container{
        // minus the height of the toolbar
        height: calc(100% - 28px);
      }

      > section.choose-type{
        position: absolute;
        top: 0; right: -90px;
        // for react-joyride
        padding-bottom: 20px;
        > label{
          display: block;
          font-size: 10px;
          padding-bottom: 8px;
          text-align: center;
          color: rgb(226, 230, 246);
          letter-spacing: 0.2px;
        }
        > .buttons > button.button{
          font-size: 9px;
          padding: 7px 4px;
          opacity: 0.5;
          width: 84px;
          min-width: auto;
          background: $orange;
          color: white;
          font-weight: 300;
          border-radius: 3px;
          &.-active{
            opacity: 1;
            cursor: default;
          }
          &:not(.-active):hover{
            opacity: 0.7;
          }
          &:first-child{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:last-child{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }

  section.courses{
    .list-of-courses{
      display: flex;
      justify-content: space-between;
      max-width: 940px;
      margin: 0 auto;
      margin-top: 80px;
      .standard-course-card{
        transition-duration: 300ms;
        .total-amount-of-flashcards{
          transition-duration: 300ms;
          background: $orange;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 21px;
        }
        h2.title{
          margin-top: 20px;
        }
        .description{
          display: none;
        }
        &:hover{
          background: rgb(29, 34, 69);
          &:hover{
            .total-amount-of-flashcards{
              height: 35px;
              font-size: 16px;
            }
          }
        }
        &:focus-within{
          box-shadow: 0 0 1px 1px $orange;
        }
      }
    }
  }

  section.sign-in{
    text-align: center;
    img{
      width: 50px;
      margin-top: 100px;
    }
    div.sign-in-buttons{
      display: block;
      max-width: 304px;
      a{ font-size: 16px; }
      a.-github{
        margin-top: 30px;
      }
    }
  }

  .-glow {
    text-align: center;
    text-shadow: 0 0 10px rgba(136, 125, 220, 0.5), 0 0 20px rgba(136, 125, 220, 0.5), 0 0 30px rgba(136, 125, 220, 0.5), 0 0 40px rgba(136, 125, 220, 0.5), 0 0 50px rgba(136, 125, 220, 0.5), 0 0 60px rgba(136, 125, 220, 0.5), 0 0 70px rgba(136, 125, 220, 0.5);
  }


  @media(max-width: 800px) {
    .standard-article-formatting{
      padding-top: 50px;  
      section.article-headings{
        margin-bottom: 0;
        h1{
          font-size: 42px;
          line-height: 54px;
        }
      }
      h2.section-heading{
        font-size: 39px;
      }
    }

    section.two-types-of-flashcards{
      ol.two-types-of-flashcard{
        flex-wrap: wrap;
        li{
          width: 100%;
          margin: 0;
        }
        li.inline{
          margin-top: 40px;
        }
      }
    }
    section.courses{
      .list-of-courses{
        flex-wrap: wrap;
        justify-content: space-around;

        a.standard-course-card{
          margin-bottom: 50px;
        }
      }
    }
  }

  @media(max-width: 750px) {
    section.creation{ display: none; }

    section.article-section.features{
      .feature-box{
        width: 100%;
      }
    }
  }

  @media(max-width: 1200px) {
    section.creation{
      section.choose-type{ display: none; }
    }
  }
}

