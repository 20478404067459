@import 'css/variables';

.standard-modal{
  // ___Why not 100% instead?
  //    Because 100% would be 100% of the .modal-wrapper width, which is not 100%.
  width: 100vw;
  // Set width width by default
  max-width: 1000px;
  margin-top: 20px;
  margin-bottom: 20px;

  &.standard-modal--md{
    max-width: 600px;
  }

  &.standard-modal--sm{
    max-width: 400px;
  }

  background: rgb(34, 38, 74);
  border-radius: 4px;

  h2.standard-modal__title{
    font-size: 30px;
    font-weight: 100;
    margin-bottom: 20px;
  }

  .standard-modal__description{
    color: rgb(175, 180, 242);
    font-weight: 100;
    font-size: 14px;
    padding-right: 100px;
  }

  .standard-modal__header{
    padding: 20px;
    padding-bottom: 0;
    background: rgb(34, 39, 74);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .standard-modal__main{
    padding: 30px 20px;
    background: rgba(25, 26, 46, 0.27);
    background: rgb(48, 52, 96);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
