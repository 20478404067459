:local(.main){
  background: white;
  padding-bottom: 60px;
  header, footer{
    display: none !important;
  }
  section.course-actions{
    .title-and-buttons{
      background: white;
    }
    .title{
      color: black;
      font-family: 'Open Sans';
    }
    .buttons{
      display: none !important;
    }
  }
  div.problem-wrapper{
    max-width: 1210px;
    margin: 0 auto;
    margin-bottom: 20px; margin-top: 60px;
    position: relative;
    .index{
      color: rgb(130, 130, 130);
      position: absolute;
      top: 11px;
      left: 11px;
      background: rgb(240, 240, 240);
      width: 20px;
      height: 20px;
      border-radius: 2px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      padding-top: 3px;
      z-index: 10000;
    }
    section.problem{
      box-shadow: 0px 0px 32px 4px rgba(224, 174, 234, 0.08);
      padding: 18px;
      border-radius: 5px;

      .quill{
        height: 100%;
        .ql-editor{
          line-height: 28px;
          font-size: 16px;
          border: 2px solid rgba(240, 240, 240, 0.9) !important;
        }
      }
    }
  }
}
