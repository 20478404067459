$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';

// for flashcards, we need all the languages and weights!
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');
// for the UI
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,500,700');

@import 'css/variables';

@import 'css/clear-default-css';
@import 'css/focus-styles';
@import 'css/bright-theme';

@import 'css/react-select';
@import 'css/quill/index';
@import 'css/tippy-tooltip';

@import 'css/container';
@import 'css/button';
@import 'css/action';
@import 'css/standard-form';
@import 'css/standard-input';
@import 'css/standard-dropdown';
@import 'css/standard-tooltip';
@import 'css/standard-article-formatting';
@import 'css/standard-modal';
@import 'css/standard-tab-navigation';
@import 'css/standard-success-message';
@import 'css/standard-title-and-description';
@import 'css/standard-dropdown-with-arrow';
@import 'css/standard-navigation_and_courses';
@import 'css/standard-course-card';
@import 'css/standard-tooltip-dropdown';
@import 'css/standard-table';
@import 'css/loading';

@import 'css/problem';

// probably useless, get rid of this?
div.space{
  margin-top: 35px;
}
// experimental good-for-bg design
main{
  // We might want a shared bg for this in the future!
  // .ProblemBeingSolved .Subheader,
  // .course-description-and-stats,
  // .title-and-buttons{
  //   background: none !important;
  // }

  .ProblemBeingSolved .problem, header{
    background: rgba(247, 247, 253, 0.04) !important;
  }
  .ProblemBeingSolved .problem{    
    @media(max-width: 900px){
      background: none !important;
    }
  }

  section.problem .ql-editor, .ql-toolbar, .see-answer-button{
    background: #22264ac2;
    &:focus{
      background: #22264aed;
    }
  }
}
