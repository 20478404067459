@import 'css/variables';

$color_1: #777;
$color_2: #fff;
$color_3: #e60000;
$color_4: #f90;
$color_5: #ff0;
$color_6: #008a00;
$color_7: #06c;
$color_8: #93f;
$color_9: rgba(0,0,0,0.6);
$color_10: #f8f8f2;
$color_11: white;
$color_12: #ccc;
$color_13: rgb(0, 0, 0);
$font_family_1: Helvetica, Arial, sans-serif;
$font_family_2: Georgia, Times New Roman, serif;
$font_family_3: Monaco, Courier New, monospace;
$background_color_1: #000;
$background_color_2: #e60000;
$background_color_3: #f90;
$background_color_4: #ff0;
$background_color_5: #008a00;
$background_color_6: #06c;
$background_color_7: #93f;
$background_color_8: #f0f0f0;
$background_color_9: #23241f;
$border_color_1: #000;

.ql-container {
  box-sizing: border-box;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
}
.ql-container.ql-disabled {
  .ql-tooltip {
    visibility: hidden;
  }
  .ql-editor {
    ul[data-checked] {
      >li {
        &::before {
          pointer-events: none;
        }
      }
    }
  }
}
.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
  p {
    margin: 0;
    padding: 0;
  }
}
.ql-editor {
  box-sizing: border-box;
  cursor: text;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  p {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    >li {
      list-style-type: none;
    }
    li {
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-num;
      &:before {
        content: counter(list-num, decimal) '. ';
      }
    }
    li.ql-indent-1 {
      counter-increment: list-1;
      counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-1, decimal) '. ';
      }
    }
    li.ql-indent-2 {
      counter-increment: list-2;
      counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-2, decimal) '. ';
      }
    }
    li.ql-indent-3 {
      counter-increment: list-3;
      counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-3, decimal) '. ';
      }
    }
    li.ql-indent-4 {
      counter-increment: list-4;
      counter-reset: list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-4, decimal) '. ';
      }
    }
    li.ql-indent-5 {
      counter-increment: list-5;
      counter-reset: list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-5, lower-roman) '. ';
      }
    }
    li.ql-indent-6 {
      counter-increment: list-6;
      counter-reset: list-7 list-8 list-9;
      &:before {
        content: counter(list-6, decimal) '. ';
      }
    }
    li.ql-indent-7 {
      counter-increment: list-7;
      counter-reset: list-8 list-9;
      &:before {
        content: counter(list-7, lower-alpha) '. ';
      }
    }
    li.ql-indent-8 {
      counter-increment: list-8;
      counter-reset: list-9;
      &:before {
        content: counter(list-8, lower-roman) '. ';
      }
    }
    li.ql-indent-9 {
      counter-increment: list-9;
      &:before {
        content: counter(list-9, decimal) '. ';
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    >li {
      list-style-type: none;
      &::before {
        content: '\2022';
      }
    }
  }
  pre {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  blockquote {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h1 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h2 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h3 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h4 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h5 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ul[data-checked=true] {
    pointer-events: none;
    >li {
      * {
        pointer-events: all;
      }
      &::before {
        color: $color_1;
        cursor: pointer;
        pointer-events: all;
        content: '\2611';
      }
    }
  }
  ul[data-checked=false] {
    pointer-events: none;
    >li {
      * {
        pointer-events: all;
      }
      &::before {
        color: $color_1;
        cursor: pointer;
        pointer-events: all;
        content: '\2610';
      }
    }
  }
  li {
    &::before {
      display: inline-block;
      white-space: nowrap;
      width: 1.2em;
      text-align: right;
      margin-right: 0.3em;
      margin-left: -1.5em;
    }
  }
  li.ql-direction-rtl {
    &::before {
      text-align: left;
      margin-left: 0.3em;
    }
  }
  .ql-indent-1 {
    &:not(.ql-direction-rtl) {
      padding-left: 3em;
    }
  }
  li.ql-indent-1 {
    &:not(.ql-direction-rtl) {
      padding-left: 20px;
    }
  }
  .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }
  li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }
  .ql-indent-2 {
    &:not(.ql-direction-rtl) {
      padding-left: 6em;
    }
  }
  li.ql-indent-2 {
    &:not(.ql-direction-rtl) {
      padding-left: 40px;
    }
  }
  .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }
  li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }
  .ql-indent-3 {
    &:not(.ql-direction-rtl) {
      padding-left: 60px;
    }
  }
  li.ql-indent-3 {
    &:not(.ql-direction-rtl) {
      padding-left: 10.5em;
    }
  }
  .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }
  li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }
  .ql-indent-4 {
    &:not(.ql-direction-rtl) {
      padding-left: 80em;
    }
  }
  li.ql-indent-4 {
    &:not(.ql-direction-rtl) {
      padding-left: 13.5em;
    }
  }
  .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }
  li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }
  .ql-indent-5 {
    &:not(.ql-direction-rtl) {
      padding-left: 15em;
    }
  }
  li.ql-indent-5 {
    &:not(.ql-direction-rtl) {
      padding-left: 16.5em;
    }
  }
  .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }
  li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }
  .ql-indent-6 {
    &:not(.ql-direction-rtl) {
      padding-left: 18em;
    }
  }
  li.ql-indent-6 {
    &:not(.ql-direction-rtl) {
      padding-left: 19.5em;
    }
  }
  .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }
  li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }
  .ql-indent-7 {
    &:not(.ql-direction-rtl) {
      padding-left: 21em;
    }
  }
  li.ql-indent-7 {
    &:not(.ql-direction-rtl) {
      padding-left: 22.5em;
    }
  }
  .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }
  li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }
  .ql-indent-8 {
    &:not(.ql-direction-rtl) {
      padding-left: 24em;
    }
  }
  li.ql-indent-8 {
    &:not(.ql-direction-rtl) {
      padding-left: 25.5em;
    }
  }
  .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }
  li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }
  .ql-indent-9 {
    &:not(.ql-direction-rtl) {
      padding-left: 27em;
    }
  }
  li.ql-indent-9 {
    &:not(.ql-direction-rtl) {
      padding-left: 28.5em;
    }
  }
  .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }
  li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }
  .ql-video {
    display: block;
    max-width: 100%;
  }
  .ql-video.ql-align-center {
    margin: 0 auto;
  }
  .ql-video.ql-align-right {
    margin: 0 0 0 auto;
  }
  .ql-bg-black {
    background-color: $background_color_1;
  }
  .ql-bg-red {
    background-color: $background_color_2;
  }
  .ql-bg-orange {
    background-color: $background_color_3;
  }
  .ql-bg-yellow {
    background-color: $background_color_4;
  }
  .ql-bg-green {
    background-color: $background_color_5;
  }
  .ql-bg-blue {
    background-color: $background_color_6;
  }
  .ql-bg-purple {
    background-color: $background_color_7;
  }
  .ql-color-white {
    color: $color_2;
  }
  .ql-color-red {
    color: $color_3;
  }
  .ql-color-orange {
    color: $color_4;
  }
  .ql-color-yellow {
    color: $color_5;
  }
  .ql-color-green {
    color: $color_6;
  }
  .ql-color-blue {
    color: $color_7;
  }
  .ql-color-purple {
    color: $color_8;
  }
  .ql-font-serif {
  }
  .ql-font-monospace {
  }
  .ql-size-small {
    font-size: 0.75em;
  }
  .ql-size-large {
    font-size: 1.5em;
  }
  .ql-size-huge {
    font-size: 2.5em;
  }
  .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-align-right {
    text-align: right;
  }
}
.ql-editor.ql-blank {
  &::before {
    color: $color_9;
    content: attr(data-placeholder);
    font-style: italic;
    pointer-events: none;
    position: absolute;
  }
}
.ql-snow.ql-toolbar {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    padding: 3px 5px;
    width: 28px;
    svg {
      float: left;
      height: 100%;
    }
    &:active {
      &:hover {
        outline: none;
      }
    }
    &:hover {
      color: $color_7;
      .ql-fill {
        fill: #06c;
      }
      .ql-stroke.ql-fill {
        fill: #06c;
      }
    }
  }
  input.ql-image[type=file] {
    display: none;
  }
  button.ql-active {
    color: $color_7;
    .ql-fill {
      fill: #06c;
    }
    .ql-stroke.ql-fill {
      fill: #06c;
    }
  }
  .ql-picker-label {
    &:hover {
      color: $color_7;
      .ql-fill {
        fill: #06c;
      }
      .ql-stroke.ql-fill {
        fill: #06c;
      }
    }
  }
  .ql-picker-label.ql-active {
    color: $color_7;
    .ql-fill {
      fill: #06c;
    }
    .ql-stroke.ql-fill {
      fill: #06c;
    }
  }
  .ql-picker-item {
    &:hover {
      color: $color_7;
      .ql-fill {
        fill: #06c;
      }
      .ql-stroke.ql-fill {
        fill: #06c;
      }
    }
  }
  .ql-picker-item.ql-selected {
    color: $color_7;
    .ql-fill {
      fill: #06c;
    }
    .ql-stroke.ql-fill {
      fill: #06c;
    }
  }
}
.ql-snow {
  .ql-toolbar {
    &:after {
      clear: both;
      content: '';
      display: table;
    }
    button {
      background: none;
      border: none;
      cursor: pointer;
      display: inline-block;
      float: left;
      height: 24px;
      padding: 3px 5px;
      width: 28px;
      svg {
        float: left;
        height: 100%;
      }
      &:active {
        &:hover {
          outline: none;
        }
      }
      &:hover {
        color: $color_7;
        .ql-fill {
          fill: #06c;
        }
        .ql-stroke.ql-fill {
          fill: #06c;
        }
      }
    }
    input.ql-image[type=file] {
      display: none;
    }
    button.ql-active {
      color: $color_7;
      .ql-fill {
        fill: #06c;
      }
      .ql-stroke.ql-fill {
        fill: #06c;
      }
    }
    .ql-picker-label {
      &:hover {
        color: $color_7;
        .ql-fill {
          fill: #06c;
        }
        .ql-stroke.ql-fill {
          fill: #06c;
        }
      }
    }
    .ql-picker-label.ql-active {
      color: $color_7;
      .ql-fill {
        fill: #06c;
      }
      .ql-stroke.ql-fill {
        fill: #06c;
      }
    }
    .ql-picker-item {
      &:hover {
        color: $color_7;
        .ql-fill {
          fill: #06c;
        }
        .ql-stroke.ql-fill {
          fill: #06c;
        }
      }
    }
    .ql-picker-item.ql-selected {
      color: $color_7;
      .ql-fill {
        fill: #06c;
      }
      .ql-stroke.ql-fill {
        fill: #06c;
      }
    }
  }
  .ql-hidden {
    display: none;
  }
  .ql-out-bottom {
    visibility: hidden;
  }
  .ql-out-top {
    visibility: hidden;
  }
  .ql-tooltip {
    z-index: 100;
    position: absolute;
    transform: translateY(10px);
    box-shadow: 0px 0px 4px #0303062e;
    color: rgb(255, 255, 255);
    padding: 5px 12px;
    white-space: nowrap;
    background-color: #35375e;
    border-radius: 3px;
    a {
      cursor: pointer;
      text-decoration: none;
      line-height: 26px;
    }
    &::before {
      content: "Visit URL:";
      line-height: 26px;
      margin-right: 8px;
    }
    input[type=text] {
      display: none;
      font-size: 13px;
      height: 26px;
      padding: 3px 5px;
      width: 300px;
      background: $color-main-3;
      border-bottom: 1px solid rgb(33, 35, 54);
      color: white;
    }
    a.ql-preview {
      display: inline-block;
      max-width: 200px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
      // color: $green !important;
      text-decoration: underline;
    }
    a.ql-action {
      &::after {
        border-right: 1px solid #ccc;
        content: 'Edit';
        margin-left: 16px;
        padding-right: 8px;
      }
    }
    a.ql-remove {
      &::before {
        content: 'Remove';
        margin-left: 8px;
      }
    }
  }
  .ql-tooltip.ql-flip {
    transform: translateY(-10px);
  }
  .ql-formats {
    display: inline-block;
    vertical-align: middle;
    &:after {
      clear: both;
      content: '';
      display: table;
    }
  }
  .ql-stroke {
    fill: none;
    stroke: white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1;
  }
  .ql-stroke-miter {
    fill: none;
    stroke: white;
    stroke-miterlimit: 10;
    stroke-width: 1;
  }
  .ql-fill {
    fill: white;
  }
  .ql-stroke.ql-fill {
    fill: white;
  }
  .ql-empty {
    fill: none;
  }
  .ql-even {
    fill-rule: evenodd;
  }
  .ql-thin {
    stroke-width: 1;
  }
  .ql-stroke.ql-thin {
    stroke-width: 1;
  }
  .ql-transparent {
    opacity: 0.4;
  }
  .ql-direction {
    svg {
      &:last-child {
        display: none;
      }
    }
  }
  .ql-direction.ql-active {
    svg {
      &:last-child {
        display: inline;
      }
      &:first-child {
        display: none;
      }
    }
  }
  .ql-picker {
    color: $color_11;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    position: relative;
    vertical-align: middle;
    &:not(.ql-color-picker) {
      &:not(.ql-icon-picker) {
        svg {
          position: absolute;
          margin-top: -9px;
          right: 0;
          top: 50%;
          width: 18px;
        }
      }
    }
  }
  .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%;
    &::before {
      display: inline-block;
      line-height: 22px;
    }
  }
  .ql-picker-options {
    background-color: white;
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
    .ql-picker-item {
      cursor: pointer;
      display: block;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }
  .ql-picker.ql-expanded {
    .ql-picker-label {
      color: $color_12;
      z-index: 2;
      .ql-fill {
        fill: #ccc;
      }
      .ql-stroke {
        stroke: #ccc;
      }
    }
    .ql-picker-options {
      display: block;
      margin-top: -1px;
      top: 100%;
      z-index: 1;
    }
  }
  .ql-color-picker {
    width: 28px;
    .ql-picker-label {
      padding: 2px 4px;
      svg {
        right: 4px;
      }
    }
    .ql-picker-options {
      padding: 3px 5px;
      width: 152px;
    }
    .ql-picker-item {
      border: 1px solid transparent;
      float: left;
      height: 16px;
      margin: 2px;
      padding: 0px;
      width: 16px;
    }
  }
  .ql-icon-picker {
    width: 28px;
    .ql-picker-label {
      padding: 2px 4px;
      svg {
        right: 4px;
      }
    }
    .ql-picker-options {
      padding: 4px 0px;
    }
    .ql-picker-item {
      height: 24px;
      width: 24px;
      padding: 2px 4px;
    }
  }
  .ql-picker.ql-size {
    .ql-picker-label[data-label] {
      &:not([data-label='']) {
        &::before {
          content: attr(data-label);
        }
      }
    }
    .ql-picker-item[data-label] {
      &:not([data-label='']) {
        &::before {
          content: attr(data-label);
        }
      }
    }
    width: 98px;
    .ql-picker-label {
      &::before {
        content: 'Normal';
      }
    }
    .ql-picker-item {
      &::before {
        content: 'Normal';
      }
    }
    .ql-picker-label[data-value=small] {
      &::before {
        content: 'Small';
      }
    }
    .ql-picker-item[data-value=small] {
      &::before {
        content: 'Small';
        font-size: 10px;
      }
    }
    .ql-picker-label[data-value=large] {
      &::before {
        content: 'Large';
      }
    }
    .ql-picker-item[data-value=large] {
      &::before {
        content: 'Large';
        font-size: 18px;
      }
    }
    .ql-picker-label[data-value=huge] {
      &::before {
        content: 'Huge';
      }
    }
    .ql-picker-item[data-value=huge] {
      &::before {
        content: 'Huge';
        font-size: 32px;
      }
    }
  }
  .ql-color-picker.ql-background {
    .ql-picker-item {
      background-color: white;
    }
  }
  .ql-color-picker.ql-color {
    .ql-picker-item {
      background-color: $background_color_1;
    }
  }
  .ql-tooltip.ql-editing {
    a.ql-preview {
      display: none;
    }
    a.ql-remove {
      display: none;
    }
    input[type=text] {
      display: inline-block;
    }
    a.ql-action {
      &::after {
        border-right: 0px;
        content: 'Save';
        padding-right: 0px;
      }
    }
  }
  .ql-tooltip[data-mode=link] {
    &::before {
      content: "Enter link:";
    }
  }
  .ql-tooltip[data-mode=formula] {
    &::before {
      content: "Enter formula:";
    }
  }
  .ql-tooltip[data-mode=video] {
    &::before {
      content: "Enter video:";
    }
  }
}
.ql-toolbar.ql-snow {
  .ql-formats {
    margin-right: 15px;
  }
  .ql-picker-label {
    border: 1px solid transparent;
  }
  .ql-picker-options {
    border: 1px solid transparent;
    box-shadow: rgba(0,0,0,0.2) 0 2px 8px;
  }
  .ql-color-picker {
    .ql-picker-item.ql-selected {
      border-color: $border_color_1;
    }
    .ql-picker-item {
      &:hover {
        border-color: $border_color_1;
      }
    }
  }
  &+.ql-container.ql-snow {
    border-top: 0px;
  }
}
