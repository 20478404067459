@import 'css/variables';
:local(.tab){
  form.standard-form{
    max-width: 680px;
    margin: 0 auto;
    > section.form-line{
      > label{ width: 200px; flex-shrink: 0; flex-grow: 1; }
      > div.input-and-validation-error{ width: 100%; flex-shrink: 1; flex-grow: 1; }
    }
    .quill > .ql-container > .ql-editor p{
      color: white !important;
    }
  }
}
