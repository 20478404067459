:local(.main){
  .ProblemBeingSolved{
    padding-bottom: 90px;
  }
  &.-hide-draft{
    .ProblemBeingSolved .draft-answer{
      display: none !important;
    }
  }
  &.-is-embed{
    header,
    section.course-actions{
      display: none !important;
    }
    .review-container .problem{ margin-top: 30px; }
    .self-score{ margin-top: 35px; }
    .next-button{ margin-top: 30px; }
  }
  footer{
    display: none;
  }
  .loading.loading-flashcards{
    margin-top: 100px;
  }
  @media(max-width: 900px){
    > header section.search,
    > header nav{
      display: none;
    }
  }
}
