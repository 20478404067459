@import 'css/variables';

:local(main.main){
  .standard-course-card{
    .title{
      margin-top: 23px;
    }
    .description{
      display: none;
    }
  }

  .home-section{
    display: flex;
    align-items: flex-start;
    padding: 20px;
    background: rgb(20, 23, 46);
    border-radius: 10px;
    margin-bottom: 20px;
    h1{
      width: 298px;
      flex-shrink: 0;
    }

    .standard-course-card{
      margin-bottom: 0;
    }

    .layout-div{
      display: none;
    }

    // .standard-course-card .title{}
  }

  h1{
    margin-bottom: 20px;
    margin-bottom: 40px;
  }


}
