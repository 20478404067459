@import 'css/variables';

.extendable-pretty-input{
  width: 100%;
  padding: 10px 20px;
  background: $color-main-3;
  color: white;
  font-size: 15px;
  border-radius: 2px;
  &::placeholder{
    color: rgb(136, 140, 202);
  }
}

.standard-input{
  &.-TextInput{
    @extend .extendable-pretty-input;
  }
  &.-EditorTextarea{
    &.quill{
      max-width: 100%;
      .ql-container{
        // border: 1px solid rgb(195, 195, 195) !important;
      }
      .ql-editor{
        @extend .extendable-pretty-input;
        min-height: 70px;
      }
    }
  }
  &.-Select{
    button.toggler{
      font-size: 15px;

      cursor: pointer;
      // center text and an arrow
      display: flex;
      justify-content: space-between;
      align-items: center;

      i.fa-caret-down{
        color: $purple;
        padding-left: 10px;
      }
      @extend .extendable-pretty-input;
    }

    .standard-dropdown{
      a, button{
        padding: 10px;
        padding-left: 20px;
      }
    }
  }
}
