// footer positioning depends on it!
html{ height: 100%; }
body{
  position: relative;
  min-height: 100%;
  padding-bottom: 1px;
}
#root{
  // height: 100%;
}
main{
  min-height: 100vh;
  // background: url(https://images.unsplash.com/photo-1545427782-c1ba83cbb692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  &.-halloween{
    background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
  }

  &.-in-monospace{
    .quill{
      pre.ql-syntax, code{
        font-family: 'monospace';
      }
    }
  }

  &.-in-normal-font{
    .quill{
      pre.ql-syntax, code{
        font-family: 'Open Sans';
      }
    }
  }
}

body{
  font-family: $body-font, serif;
  color: $body-color;
  margin: 0;
  box-sizing: border-box;
  font-weight: 300;

  background: $color-main-2;
}
a{
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
label{
  font-weight: 300;
}
h1, h2, h3, h4, h5, h6{
  margin-top: 0; margin-bottom: 0;
  font-weight: 300;
  letter-spacing: 0.3px;
  font-family: $heading-font;
}
// only use these
h1{ font-size: 25px; }
h2{ font-size: 22px; }

table{
  border-collapse: collapse;
}

figure{
  margin: 0;
}

input[type="text"], input[type="search"], input[type="email"], textarea, button, a{
  // border: 1px solid transparent;
  border: none;
  color: inherit;
}

input, textarea, select, button{
  // because mac makes it tiny 11px
  font-size: inherit;
  // because font defaults to system despite body style
  font-family: $body-font;
  font-weight: 300;

  background: none;
  padding: 0;
}

button{
  cursor: pointer;
}

mark{
  background: none;
}

ul, ol{
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

// has big up/down margin by default
p{
  margin: 7px 0;
}

// mac's chrome rounds all corners by default
button{
  border-radius: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
