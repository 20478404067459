

*{
  outline: none;
}

header .avatar{
  &:hover, &:focus{
    opacity: 0.7;
  }
}

input.standard-input:not(.answer-input):not(.toggler),
.standard-input.-Select button.toggler,
.standard-dropdown a, .standard-dropdown button,
.standard-input.-Select ul.groups button{
  &:focus, &:hover{
    background: rgb(27, 31, 65);
  }  
}

.button.-purple, .button.purple{
  &:focus{
    box-shadow: inset 0 0 0 2px rgb(182, 173, 244);
  }
}

.button.-white, button.volume-button, button.bg-image-button, .more-button{
  &:focus, &:hover, &[aria-expanded="true"]{
    // opacity: 0.09;
    background: rgba(255, 255, 255, 0.09);
  }
}
button.volume-button, button.bg-image-button{
  border-radius: 3px;
}

.toggle-button.-left{
  &.focus-visible, &:hover{
    .toggle-button-label-left:after{
      background: rgba(255, 255, 255, 0.09);
    }
  }
}

.button.-to-review, .button.-orange{
  &:hover, &:focus{
    background: rgb(250, 92, 18);
  }
}

.button.-to-learn{
  &:hover, &:focus{
    background: rgb(46, 203, 161);
  }
}

ul.categories a.name,
.notifications-toggler,
button.edit-button, button.invite-coauthor-button, .stop-learning-button,
section.course-actions h1.title a{
  &:hover, &:focus{
    background: rgba(243, 243, 249, 0.05) !important;
  }
}

header a.logo.focus-visible{
  background: rgba(243, 243, 249, 0.05) !important;
}

header nav{
  .link{
    &.active{
      background: rgba(243, 243, 249, 0.07);
    }
    &:hover:not(.active), &:focus:not(.active){
      background: rgba(243, 243, 249, 0.05);
    }
  }
  .my-courses-toggler[aria-expanded=true] .link.courses{
    background: rgba(243, 243, 249, 0.07);
  }
  .my-courses-toggler[aria-expanded=false] .link.courses:hover{
    background: rgba(243, 243, 249, 0.05) !important;
  }
}

@media(min-width: 460px){
  .standard-course-card{
    &:focus-within, &:hover{
      outline: none;
      background: rgb(32, 35, 73);
    }
  }
}

.see-answer-button:hover, .see-answer-button.focus-visible{
  border: 4px solid rgb(160, 247, 200) !important;
}


input.answer-input:focus{
  background: rgba(136, 125, 220, 0.16) !important;
  box-shadow: none;
}

section.course-actions{
  .category_and_author a{
    &:focus, &:hover{
      text-decoration: underline;
    }
  }
  button.edit-button, button.invite-coauthor-button, .stop-learning-button{
    &:focus, &:hover{
      background: rgba(32, 35, 73, 0.55);
    }
  }
  h1.title a{
    border-radius: 2px;
    padding: 5px;
    &:hover, &:focus{
      background: rgba(32, 35, 73, 0.55);
    }
  }
}

.standard-tab-navigation button, .ReactModal__Content .close-button{
  &:focus, &.-selected{
    background: rgba(48, 52, 96, 0.62);
  }
}

