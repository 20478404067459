.button{
  min-width: 140px;

  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;

  padding: 13px 20px;
  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
  // needed too, for responsive design!
  text-align: center;

  border-radius: 2px;
  &.-clear{
    font-size: inherit;
    text-transform: none;
    padding: 0;
    letter-spacing: inherit;
    min-width: none;
    text-align: inherit;
    display: block;
  }
  &.-black{
    color: white;
    background: #333030;
  }
  &.-blue{
    color: white;
    background: #1C263C;
  }
  &.-purple{
    color: white;
    background: $purple;
    // &:focus{
    //   box-shadow: 0 0 3px white;
    //   border: 1px solid white !important;
    // }
  }
  // -outlines, just like in fontawesome
  &.-purple-o{
    color: $purple;
    border: 1px solid $purple;
  }
  &.-white{
    color: white;
    background: rgba(255, 255, 255, 0.07);
  }
  &.-orange{
    color: white;
    background: $orange;
    &:hover{
      transition: background 60ms ease-in-out;
      background: rgba(250, 100, 0, 0.9);
    }
  }
  &.-orange-o{
    color: rgb(244, 106, 13);
    border: 2px solid $orange;
    &:hover{
      transition: border 60ms ease-in-out;
      border: 2px solid rgb(250, 100, 0);
    }
  }

  &.-to-learn, &.-to-review{
    color: white;
    font-size: 10px;
    letter-spacing: 0;
    // &:focus{
    //   box-shadow: none;
    //   border: 1px solid white !important;
    // }
    &.-disabled{
      background: rgba(30, 31, 56, 0.8);
      color: rgb(117, 122, 146);
      opacity: 1 !important;
    }
    @media(max-width: 400px){
      min-width: 85px !important;
    }
    @media(max-width: 350px){
      min-width: 73px !important;
    }
  }
  &.-to-learn{
    background: $learn-button-color;
  }
  &.-to-review{
    background: $review-button-color;
  }

  &.-pink{
    color: white;
    background: $purple;
  }

  &.-red{
    color: white;
    background: rgb(133, 29, 50);
  }
  &.-red-o{
    color: rgb(225, 65, 65);
  }

  &.-green{
    background: $green;
  }

  &.-small{
    font-size: 9px;
    padding: 5px 10px;
  }

  &.-with-radius{
    border-radius: 5px;
  }

  &.-disabled{
    opacity: 0.2;
    cursor: default;
  }
}

.-fade-out-on-hover{
  transition: all 60ms ease-in-out;
  opacity: 1;
  &:hover{ opacity: 0.85; }
}
.-fade-in-on-hover{
  transition: opacity 60ms ease-in-out;
  opacity: 0.85;
  &:hover{ opacity: 1; }
}

.-move-up-on-hover{
  transform: translateY(0px);
  transition: transform 60ms ease-in-out;
  &:hover{
    transform: translateY(-1px);
  }
}
