@import 'css/variables';

.quill > .ql-toolbar{
  border: none;
  padding: 2px 10px;
  background: rgb(34, 38, 74);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  button:hover, button.ql-active{
    .ql-stroke{
      stroke: $green !important;
    }
    .ql-fill{
      fill: $green !important;
    }
  }
  .ql-formats{
    display: inline-block;;
    &:last-child{
      margin-right: 0;
    }
  }

  // Only show Italitc and Unerline buttons on mobiles.
  // The logic is that on desktop people can use shortcuts & we don't want too many buttons in the toolbar, see https://github.com/lakesare/memcode/pull/172.
  @media(min-width: 950px){
    .ql-italic, .ql-underline{
      display: none;
    }
  }
}
.ql-tooltip{
  input{
    border-radius: 3px;
    padding: 3px 10px;
  }
  input::-webkit-input-placeholder{
    color: rgb(86, 90, 158);
  }
  .ql-action:hover, .ql-remove:hover{
    // color: rgb(187, 191, 234);
    opacity: 0.9;
  }
}
.quill-toolbar-tooltip{
  text-align: center;
  .explanation{
    font-size: 11px;
  }
  .shortcut{
    color: $green;
    font-size: 11px;
  }
  .instruction{
    color: $purple;
    font-size: 10px;
  }
}
