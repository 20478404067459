@import 'css/variables';

:local(main.main){

  article{
    max-width: 350px;
  }
  .sign-in-buttons{
    margin-top: 50px;
  }
}

:local(main.main){
  @media(max-width: 420px){
    .sign-in-buttons{
      flex-wrap: wrap;
      > .button.sign-in{
        flex-basis: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
