@import 'css/variables';

:local(.local){
  max-width: 650px;

  .standard-modal__main{
    min-height: 600px;
  }

  h2.title{
    margin-bottom: 10px;
  }

  .design-tab{
    section.part-of-the-website{
      margin-bottom: 30px;


      div.setting{
        margin-bottom: 10px;
        background: rgba(255, 255, 255, 0.04);
        padding: 10px;
        display: flex;
        border-radius: 3px;
        min-height: 68px;
        .comment{
          padding-top: 4px;
          font-size: 16px;
          line-height: 22px;
          width: 100%;
          display: flex;
          align-items: center;
          padding-left: 10px;
          overflow-wrap: anywhere;
        }
        button{
          font-size: 10px;
          margin-left: 20px;
          min-width: 150px;
          padding: 0;
        }
      }
    }    
  }

  .manage-tab{
    button{
      margin-top: 25px;
    }
  }

}
