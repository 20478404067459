@import 'css/variables';

section.problem{
  display: flex;
  .first-column, .second-column{
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .first-column{ padding-right: 10px; }
  .second-column{ padding-left: 10px; }

  // Css for quill editors only in flashcards
  .ql-editor{
    border-radius: 2px;

    img{
      display: block;
      margin: 0 auto;
    }
  }

  // Css for particular flashcard types
  $answer-background: rgba(45, 245, 83, 0.9);
  &.-withInlinedAnswers{
    mark.answer{
      border: 2px solid $answer-background;
      padding: 1px 2px;
      border-radius: 2px;
    }
    .ql-editor{
      border: 2px solid rgb(170, 160, 247);
    }
  }
  &.-withSeparateAnswer{
    .first-column .ql-editor{
      border: 2px solid rgb(170, 160, 247);
    }
    .second-column .ql-editor{
      border: 2px solid $answer-background;
    }
  }
}
