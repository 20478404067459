@import 'css/variables';

.standard-dropdown-wrapper{
  position: relative;
  .standard-dropdown{
    position: absolute;
    top: 100%;
    max-height: 200px; width: 100%;

    overflow-y: scroll;
    z-index: 1000;
  }
}

// can be separate
.standard-dropdown{
  font-size: 14px;
  li{
    cursor: pointer;
    > button, > a{
      color: inherit;
      width: 100%;
      height: 100%;
      text-align: left;
    }
  }
}

.standard-dropdown.-purple{
  background: $color-main-3;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  // border-top: none;
  li{
    border-bottom: 1px solid rgba(255, 255, 255, 0.01);
    &:last-child{ border-bottom: none; }
    mark{ color: $purple; }
    &:hover{
      color: $purple;
    }
  }
}
