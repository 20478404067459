@import 'css/variables';

:local(.section){
  $purple-question-border: 3px solid rgb(170, 160, 247);
  $green-answer-border: 3px solid rgb(160, 247, 200);
  $green-answer-box-shadow: 0px 0px 6px 2px rgba(143, 242, 124, 0.39);
  $purple-question-box-shadow: 0px 0px 6px 2px rgba(137, 94, 241, 0.62);

  &.-clicking{
    input.answer-input[data-answered='wrong']{
      box-shadow: $green-answer-box-shadow !important;
      border: $green-answer-border !important;
      border-width: 2px;
      background: rgb(68, 245, 149) !important;
      color: rgb(10, 80, 42) !important;
    }
    input.answer-input[data-answered='waiting']{
      // pointer-events: none;
      cursor: pointer;
    }
  }
  &.-typing{
    .answer-input[data-answered='wrong']{
      border: 2px solid #f9637d;
      color: #f9637d !important;
    }
  }

  section.problem{
    max-width: 1210px;
    margin: 0 auto;
    margin-bottom: 20px; margin-top: 60px;

    padding: 18px;
    border-radius: 5px;

    &.-withInlinedAnswers{
      .ql-editor{
        box-shadow: $purple-question-box-shadow;
      }
      input.answer-input{
        width: 120px;
        display: inline-block;
        // padding: 0 10px;
        text-align: center;

        padding: 2px 0;
        margin: 0 4px;
        border-radius: 2px;
        &:focus{ outline: none; }
        &[data-answered='right']{
          box-shadow: $green-answer-box-shadow;
          border: $green-answer-border;
          border-width: 2px;
          background: rgb(68, 245, 149) !important;
          color: rgb(10, 80, 42) !important;
        }
        &[data-answered='waiting']{
          color: rgb(206, 199, 245);
          // font-weight: 500;
          // border: 2px solid #FFEB3B;
          border: 2px solid $purple;
        }
      }
    }
    &.-withSeparateAnswer{
      flex-wrap: wrap;
      justify-content: flex-end;

      > div.question-and-answer{
        width: 100%;
        display: flex;
        .question.first-column{
          .ql-editor{
            box-shadow: $purple-question-box-shadow;
            border: $purple-question-border;
          }
        }
        .answer.second-column{
          .ql-editor{
            box-shadow: $green-answer-box-shadow;
            border: $green-answer-border;
          }
        }
        // button or answer editor
        button.see-answer-button{
          max-height: 800px;
          width: 100%;
          height: 100%;
          font-weight: 300;
          padding: 6px 0;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          user-select: none;
          border-radius: 2px;

          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          box-shadow: $green-answer-box-shadow;
          border: $green-answer-border;
          color: rgb(160, 247, 200);
        }
      }

      > div.draft-answer{
        display: flex;
        width: 100%;
        .quill{
          margin-top: 20px;
          // placeholder!
          .ql-editor.ql-blank::before{
            color: rgba(132, 155, 249, 0.48);
          }
          .ql-editor{
            border: none !important;
            background: rgb(34, 38, 74);
            min-height: 54px;
          }
          .ql-toolbar{
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            transition-duration: 300ms;
            transition-property: height, background, padding;
          }
          &:focus-within{
            .ql-toolbar{
              height: 31px;
              background: rgb(22, 25, 51);
              padding-top: 2px;
              padding-bottom: 2px;
            }
            .ql-editor{
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
          }
        }
      }
    }
  }

  button.next-button{
    max-width: 100px;
    margin: 0 auto;
    margin-top: 60px;
    border-radius: 2px;
    opacity: 0.9;
    &:hover{
      opacity: 1;
    }
  }

  button.reveal{
    color: rgba(251, 250, 242, 0.85);
    background: rgb(43, 47, 82);
    max-width: 150px;
    margin: 0 auto;
    margin-top: 60px;
    border-radius: 2px;
    &:hover{
      color: white;
    }
  }

  .n-of-problems-left{
    position: fixed;
    bottom: 0;
    left: 0; right: 0;
    z-index: 100000;
    // margin: 30px 0;

    &.-failed div.inner{
      background-color: #ff1d1d;
    }


    // position: fixed;
    // bottom: 0;
    // max-width: inherit;
    // width: 100%;
    // background: #0f1122ed;
    // width: 100%;
    // padding: 25px 101px;
    // left: 0;
    // right: 0;
    // max-width: none;
  }
}

@media(max-width: 900px) {
  :local(.section){
    padding-bottom: 133px !important;
    > section.Subheader{
      > .container{
        flex-wrap: wrap;
        padding-top: 10px;
        padding-bottom: 10px;
        .amount-of-problems-left{
          margin-top: 15px;
          margin-bottom: 15px;
          width: 100%;
        }
      }
      .-mobile{ display: block; text-align: right; }
      .-desktop{ display: none !important; }
      position: absolute;
      bottom: 11px;
      width: 100%;
    }

    .review-container{
      padding: 0;
    }
    // Must be not >, for /articles/welcome!
    section.problem{
      margin-right: 20px;
      margin-left: 20px;
      margin-top: 10px;
      padding: 0;
      box-shadow: none;
      .ql-editor{
        padding: 6px 10px;
      }
      .first-column, .second-column{
        width: 100%;
      }
      .first-column{
        padding-right: 0 !important;
      }
      .second-column{
        margin-top: 20px;
        padding-left: 0px !important;
      }
      &.-withInlinedAnswers{
        flex-wrap: wrap;
      }
      &.-withSeparateAnswer{
        flex-wrap: wrap;
        > div.question-and-answer{
          flex-wrap: wrap;
        }
        button.see-answer-button{
          min-height: 58px;
        }
        div.draft-answer{ display: none !important; }
      }
    }

    > section.self-score{
      margin-top: 25px;
      li{
        padding-bottom: 20px;
      }
    }

    > button.next-button, > button.reveal{
      margin-top: 30px;
    }
  }
}
