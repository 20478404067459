.standard-success-message{
  max-width: 640px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 30px;
  background: rgba(98, 170, 91, 0.76);
  border-radius: 2px;
  text-align: center;
  color: white;
  font-size: 14px;
}
