@import 'css/variables';

:local(.tooltip){
  .not-learning-placeholder{
    padding: 20px;
    font-size: 13px;
  }
  .course-li{
    margin-bottom: 2px;
    font-size: 15px;

    .title{
      letter-spacing: 0.3px;
      font-family: "Roboto";
      font-weight: 100;
      mark{
        color: $purple;
      }
    }

    a.title{
      background: #35395bb3;
      border-radius: 2px;

      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 10px;
      padding-right: 45px;

    }
    .action{
      width: 45px;
      position: absolute;
      right: 0; top: 0; bottom: 0;
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      &:hover{
        transition-duration: 50ms;
      }
    }

    &.-review{
      .action{
        background: $review-button-color;
      }
      a:hover{
        background: transparentize($review-button-color, 0.3);
      }
    }
    &.-learn{
      .action{
        background: $learn-button-color;

      }
      a:hover{
        background: transparentize($learn-button-color, 0.3);
      }
    }
    &.-next-due-date-in{
      a{ padding-right: 65px; }
      .action{
        background: rgb(66, 69, 98);
        width: 65px;
      }
      a:hover{
        background: transparentize(rgb(66, 69, 98), 0.3);
      }
    }
    &.-pinned{
      position: relative;
      .action{
        &.-review{
          background: $review-button-color;
          &:hover{
            background: transparentize($review-button-color, 0.1);
          }
        }
        &.-learn{
          background: $learn-button-color;
          &:hover{
            background: transparentize($learn-button-color, 0.1);
          }
        }
        // &.-pinned{
        //   cursor: default;
        //   i {
        //     font-size: 15px;
        //   }
        // }
      }
      a.title:hover{
        background: transparentize(rgb(66, 69, 98), 0.3);
      }
    }
  }
  .search-courses{
    position: relative;

    button.sync-button {
      position: absolute;
      font-size: 10px !important;
      min-width: 58px !important;
      height: 26px !important;
      top: 5px;
      right: -5px;
      padding: 0;
      &.-saving {
        cursor: default;
        pointer-events: none;

        background: linear-gradient(to right, rgba(0, 0, 0, 0) 33%, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0) 66%), rgb(30, 31, 57);
        background-size: 400% 100%, cover;
        animation: change 1s linear infinite;
      }
    
      &.-just-saved {
        color: rgb(33, 165, 153);
      }
    }
  }


}