:local(.tab){
  section.delete-course{
    > h2.title{
      font-size: 22px;
      text-align: center;
    }
    > article.instructions{
      max-width: 500px;
      margin: 0 auto;
      margin-top: 60px;
      text-align: center;

      // color: rgb(199, 196, 196);

      font-size: 15px;
      line-height: 20px;

      b{
        font-weight: 300;
        color: rgb(246, 77, 77);
      }

    }
    > button.delete-course-button{
      margin: 0 auto;
      margin-top: 60px;
    }
  }
}
