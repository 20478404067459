@import 'css/variables';

:local(.ul){
  user-select: none;

  display: flex;
  > li.page-n{
    margin: 5px;
    background: $color-main-3;
    font-size: 10px;
    border-radius: 2px;

    min-width: 20px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.-current{
      background: $purple;
    }
    a{
      &:hover, &:focus{
        background: rgb(32, 35, 73);
      }
      padding: 0px 5px;
      border-radius: 2px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  > li.epsilon{
    display: flex;
    align-items: center;
    color: $purple;
  }
}
