@import 'css/variables';

:local(main.main){
  article{

    .how-did-I-choose-sites-for-comparison{
      margin-top: 40px;
      ul{
        padding-top: 10px;
        li{ padding-bottom: 0; }
      }
    }
    // shared among children
    .sites{
      .site{
        border-left: 1px solid rgba(136, 125, 220, 0.13);
        margin-top: 20px;
        padding: 30px 50px;
        padding-right: 0;
        h3{
          margin-bottom: 17px;
          color: $purple;
          width: 160px;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 15px;
          display: flex;
          align-items: center;
          > span.rating{
            font-size: 14px;
            font-weight: 300;
            color: rgb(255, 255, 255);
            text-shadow: 0 0 5px #ffffff7d;
            padding-left: 10px;
            &:after{
              content: '/5';
            }
          }
        }
      }
      .conclusion{
        margin-top: 20px;
        padding: 20px;
        background: rgba(136, 125, 220, 0.13);
        width: 100%;
        padding-left: 50px;
        h3{
          display: none;
          font-size: 20px;
          padding: 5px;
          padding-left: 10px;
        }
      }
    }

    // child-specific
    section.article-section#types-of-tasks{
      .site.brainscape ul.list-of-flashcard-types{
        box-shadow: none;
        li{
          box-shadow: 5px 2px 20px 0px #1e203a;
        }
      }
      ul.list-of-flashcard-types{
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;

        box-shadow: 5px 2px 20px 0px #1e203a;
        li{
          width: 50%;
          padding: 14px;
          box-sizing: border-box;
          box-shadow: 0px 0px 6px 0px #0f1122;
          text-align: center;
          background: rgb(31, 32, 58);
          line-height: 22px;
          font-size: 15px;
          min-height: 205px;
          img{
            width: 100%;
            // max-width: 310px;
            margin: 0 auto;
            display: block;
            margin-top: 10px;
            // border: 2px solid rgb(8, 9, 16);
          }
        }
      }
    }

    section.article-section#when-to-use-each{
      .site h3{
        width: auto;
        justify-content: flex-start;
        > span.answer{
          padding-left: 15px;
          text-transform: none;
        }
      }
    }

    section.article-section#ui{
      .description b{
        font-weight: 300;
        color: $purple;
      }
    }
  }
}

:local(main.main){
  @media(max-width: 900px){
    .sites{
      section.site{
        padding-left: 20px;
      }
    }

    section.article-section#types-of-tasks{
      ul.list-of-flashcard-types{
        > li{
          width: 100% !important;
        }
      }
    }
  }
}
