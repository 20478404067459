.standard-navigation_and_courses{
  margin-top: 30px;
  padding-bottom: 30px;
  display: flex;
  > .left{
    width: 250px;
    flex-grow: 0;
    flex-shrink: 0;
    // border-right: 1px solid rgba(223, 201, 247, 0.06);

    margin-right: 45px;
    padding-bottom: 40px;
    margin-bottom: 50px;
  }
  > .right{
    // helps .loading to stay wide
    width: 100%;
    div.title_and_sorting{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      > h1.title{
        font-size: 32px;
        font-weight: 100;
        letter-spacing: 0.3px;
      }

      > section.sort-by-dropdown-wrapper{
        max-width: 200px;
        > button.toggler{
          padding-left: 10px;
          padding-right: 10px;
          width: 151px;
        }
        > ul.standard-dropdown{
          padding: 0;
          li{
            padding: 0;
            a{
              padding: 10px;
              display: block;
            }
          }
        }
      }
    }
  }
}

.standard-navigation_and_courses{
  @media(max-width: 1200px){
    .left{
      width: 190px;
      margin-right: 15px;
    }
  }

  @media(max-width: 900px){
    // flex-wrap: wrap;
    .left{
      display: none;
    }
  }

  @media(max-width: 470px){
    .right{
      width: 100%;
      .layout-div{
        display: none;
      }
    }
  }
}
