@import 'css/variables';

:local(.section){
  max-width: 470px;
  margin: 0 auto;
  margin-top: 60px;
  > label.rate-yourself{
    display: block;
    width: 100%;

    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.4px;
    i{
      color: rgb(170, 160, 247);
    }
  }
  > ul.stars{
    margin-top: 6px;
    display: flex;
    > li{
      cursor: pointer;
      padding-bottom: 27px;
      padding-top: 27px;
      width: 25%;
      &:first-child{
        div.star{
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
      }
      &:last-child{
        div.star{
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
      > div.star{
        padding-right: 3px; padding-left: 3px;
        width: 100%; height: 13px;
        transition-property: background;
        transition-duration: .4s;
        border-right: 1px solid rgb(14, 16, 35);
      }
      &.-not-selected > div.star{
        background: rgba(124, 114, 201, 0.11);
      }
      &.-selected > div.star{
        background: rgb(170, 160, 247);
        box-shadow: 0px 0px 12px 0 rgba(137, 94, 241, 0.43);
      }
      &:last-child > div.star{ border-right: none; }
    }
  }
}
