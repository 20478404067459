.standard-form{
  margin-bottom: 40px;
  padding: 25px 15px;

  max-width: 800px;
  margin: 40px auto;
  &.-bordered{
    background: rgb(20, 23, 46);
    border-radius: 3px;
  }

  &.-no-padding{
    padding: 0;
    margin: 0;
  }

  .form-insides{
    max-width: 640px;
    margin: 0 auto;

    > .form-line, > .two-form-lines-in-row{
      margin-top: 30px;
    }
  }

  .two-form-lines-in-row{
    display: flex;
    > .form-line{
      width: 100%;
      &:first-child{
        padding-right: 20px;
      }
      &:last-child{
        padding-left: 20px;
      }
    }
  }
  .form-line{
    &:first-child{ margin-top: 0; }
    > label{
      color: white;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.4px;
    }
    > div.input-and-validation-error{
      margin-top: 10px;
      > div.standard-input{}
      > div.validation-error{
        padding: 10px 20px;
        border-radius: 0px 0px 2px 2px;
        text-align: left;
        font-size: 12px;

        background: rgb(48, 21, 54);
        color: rgb(236, 76, 123);
      }
    }
  }
}
.standard-submit-button{
  margin: 0 auto;
  margin-top: 50px;
}

.standard-form{
  @media(max-width: 550px){
    .form-line{
      flex-wrap: wrap;
      > label{
        margin-bottom: 10px;
        width: 100%;
      }
      > div.input-and-validation-error{
        width: 100%;
        .quill > .ql-toolbar{ display: none; }
      }
    }
  }
}
