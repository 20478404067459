@import 'css/variables';

:local(main.main){
  > nav{
    > ul.tab-links{
      // background: rgb(14, 16, 49);

      display: flex;
      justify-content: center;
      align-items: center;

      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1.1px;
      > li{
        padding: 13px 20px;
        margin: 0px 5px;
        cursor: pointer;
        border-radius: 2px;
        &.-active{
          background: rgba(244, 242, 242, 0.05);
        }
        &.-not-active{
          &:hover{
            background: rgba(244, 242, 242, 0.02);
          }
        }
      }
    }
    > section.instructions{
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 13px;
      text-align: center;
      line-height: 20px;
      letter-spacing: 0.2px;
      font-family: 'Open Sans';
      em{
        font-style: normal;
        text-transform: uppercase;
      }
      em.review{ color: $review-button-color; }
      em.learn{ color: $learn-button-color; }
    }
  }

  > section.tab-content{
    margin-top: 40px;
    padding-bottom: 80px;

    div.problem-wrapper{
      padding: 23px;
      margin: 0 auto;
      margin-bottom: 10px;
      margin-right: 117px;
      margin-left: 70px;

      border-radius: 5px;
      // box-shadow: 0px 0px 32px 4px rgba(224, 174, 234, 0.08);
      box-shadow: 0px 0px 32px 4px rgba(23, 68, 68, 0.39);
      .quill{
        height: 100%;
      }
      .ql-editor{ cursor: default; }
      &.-ignored{
        background: rgb(23, 23, 22);
        section.problem{
          opacity: 0.4;
          filter: grayscale(100%);
        }
      }
      &.-learned{
        background: $learn-button-color;
        .button-wrapper.-unlearn{
          // overlay with -learn wrapper so that whole problem is clickable
          width: 100%;
          cursor: pointer;
          z-index: 1001;
        }
      }
      &.-yet-to-learn{
        &:hover{
          background: rgb(25, 70, 71);
          transition-duration: 300ms;
        }
        .button-wrapper.-learn{
          // overlay with -learn wrapper so that whole problem is clickable
          width: 100%;
          cursor: pointer;
          z-index: 1001;
        }
      }

      position: relative;
      .button-wrapper{
        position: absolute;
        top: 0; bottom: 0;
        right: 0;
        &.-disabled{
          // opacity: 0.3;
          cursor: default;
          button{ cursor: default; }
        }

        // &.-learn:hover{
        //   button{
        //     background: $learn-button-color !important;
        //   }
        // }
        button{
          position: absolute;
          left: calc(100% + 15px);
          padding: 10px 5px;
          font-size: 10px;
          min-width: 92px;
          margin-top: 23px;
          &.-ignore, &.-unignore{
            top: 42px;
          }

          // color of buttons
          &.-ignore, &.-unignore{
            color: rgb(169, 177, 223);
            background: rgb(33, 37, 67);
          }
          &.-learn, &.-unlearn{
            background: rgb(25, 70, 71);
            // color: rgb(167, 242, 244);
            color: white;
            &:hover{
              background: $learn-button-color;
              transition-duration: 300ms;
            }
          }

          // for the undo icon
          display: flex;
          align-items: center;
          > i.fa-undo{ padding-right: 5px; }
        }
      }
    }
  }

  @media(max-width: 1300px){
    .problem-wrapper{
      margin-left: 0 !important;
    }
  }

  @media(max-width: 900px){
    > header section.search{
      display: none;
    }
    nav ul.tab-links{ display: none; }
    .problem-wrapper{
      margin-right: 0 !important;
      .button-wrapper button{ display: none !important; }
      section.problem{
        display: block;
        div.first-column,
        div.second-column{
          width: 100%;
        }
        div.first-column{
          padding-right: 0;
        }
        div.second-column{
          padding-left: 0;
          padding-top: 20px;
        }
      }
    }
  }

  @media(max-width: 600px){
    .tab-content{
      padding: 0;
    }
    div.problem-wrapper{
      padding: 20px !important;
    }
  }
}
