@import 'css/variables';

.standard-tooltip-dropdown{
  text-align: right;
  li{
    font-size: 14px;
    padding: 5px 0;
    button, a{
      &:hover .text, &:hover{ opacity: 0.8; }
      width: 100%;
      text-align: right;
    }
    .comment{
      font-size: 11px;
      color: rgb(186, 178, 248);
      padding-top: 2px;
      &.-white{
        color: white;
      }
    }
  }
  li:first-child{ border-top: none; }
}
