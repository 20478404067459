@import 'css/variables';

:local(section.actions){
  > section.title-and-buttons{
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 80px;
    > .container{
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1.title{
        font-weight: 300;
        font-size: 30px;
      }

      > .buttons{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .button{
          min-width: 90px;
          font-size: 10px;
          padding: 0 10px;
          height: 31px;
        }

        > section.start-stop-or-resume-learning-course{
          .button{
            i.fa-plus{
              font-size: 14px;
              padding-right: 4px;
            }
          }
          button.stop-learning-button{
            border: 1px solid rgb(58, 65, 101);
            color: rgb(78, 87, 134);
          }

          div.loading.-request{
            font-size: 7px;
            width: 86px;
            padding: 8px;
          }
        }
        > .learn-and-review-buttons{
          display: flex;
          .button{
            margin-left: 10px;
          }
          .more-button{
            margin-left: 5px;
            min-width: 0;
            padding: 0;
            i{ font-size: 29px; }
          }
        }
      }

      > section.course-title_and_category_and_author{
        display: flex;
        min-height: 40px;
        > h1.title{
          word-break: break-all;
          max-width: 700px;
        }

        > section.category_and_author{
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-left: 14px;
          margin-left: 14px;
          border-left: 1px solid #e5e6ee1f;
          > div.category, div.author{
            cursor: default;
            height: 50%;
            display: flex;
            align-items: center;

            font-size: 10px;
            letter-spacing: 0.9px;
            text-transform: uppercase;
            > .in-or-by{
              display: inline-block;
              width: 20px;
            }
          }
        }
        > section.edit-invite-buttons{        
          margin-left: 10px;
          // margin-top: 2px;
          > button.edit-button, > button.invite-coauthor-button{
            align-self: flex-start;
            min-width: auto;
            padding: 0 10px;
            height: 50%;

            font-size: 10px;
            align-items: center;
            i{ padding-right: 3px; }
          }
        }

        .course-label{
          padding: 6px;
          align-self: flex-start;
          font-size: 10px;
          cursor: default;
          border-radius: 2px;
          margin-left: 10px;
        }

        .-private{
          color: rgb(236, 76, 123);
          background: rgba(236, 76, 123, 0.18);
        }

        .-duplicated{
          color: rgb(120, 175, 244);
          background: rgba(76, 138, 236, 0.18);
        }
      }
    }
  }
  > section.course-description-and-stats{

    .button{
      min-width: 90px;
      font-size: 11px;
      padding: 0 5px;
      height: 31px;
    }

    padding: 10px 0;
    > .container{
      display: flex;
      align-items: flex-start;

      > ul.course-stats{
        width: 311px;
        flex-grow: 0;
        flex-shrink: 1;

        cursor: default;
        > li{
          padding: 3px 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          > div.icon{
            // text-shadow: 0 0 6px #fbfbfb47;
            font-size: 15px;
            width: 27px;
            text-align: center;
          }
          > div.stat{
            font-size: 11px;
            padding-right: 10px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            span.number{
              padding-right: 2px;
              font-size: 13px;
              opacity: 0.92;
            }
            span.number.review-in-stat-number{
              padding-left: 5px;
              padding-right: 0;
              .measure{
                padding-left: 5px;
                font-size: 11px;
              }
            }
          }
        }
        > li.course-star-rating{
          min-height: 28px;
          > div.stat{
            > i.fa-user-o{
              font-size: 11px;
              padding-left: 3px;
            }
            > span.amount-of-voters{
              text-shadow: none;
              padding-left: 2px;
            }
          }
          &.-can-rate-course{
            > div.stat{
              padding-right: 3px;
            }
            > div.icon{
              width: auto;
              > ul.star-rating{
                display: flex;
                > li.-not-selected{
                  text-shadow: none;
                }
              }
            }
          }
        }
      }
      > .course-description{
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        .ql-snow.ql-container{
          border: none !important;
          > .ql-editor{
            padding: 0;
            font-size: 14px;
            min-height: initial;
            height: auto;
            overflow-y: hidden;
            cursor: default;
            .placeholder{
              padding-right: 10px;
              line-height: 20px;
              color: rgb(172, 181, 218);
              // opacity: 0;
              // transition-duration: 300ms;
              // transition-delay: 300ms;
            }
          }
        }
      }
      &:hover{
        .course-description .placeholder{
          opacity: 1 !important;
        }
      }
    }
  }
}


:local(section.actions){
  @media(max-width: 900px){
    .edit-invite-buttons{
      display: none;
    }
    section.title-and-buttons{
      padding-top: 10px;
      padding-bottom: 10px;
      .container{
        justify-content: flex-end;
        .learn-and-review-buttons{
          a.button.learn{ margin-left: 0 !important; }
        }
        h1.title{
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      > .container{
        flex-wrap: wrap;
        > section.course-title_and_category_and_author{
          flex-wrap: wrap;
          width: 100%;

          > h1.title{
            order: 1;
            font-size: 23px;
            width: 100%;
            text-align: right;
            max-width: none;
          }
          > section.category_and_author{
            display: none;
            order: 0;
            width: 100%;
            flex-direction: row;
            justify-content: flex-end;
            margin-left: 0;
            padding-left: 0;
            padding-bottom: 10px;
            border-left: none;
            > div.category{
              border-right: 1px solid rgb(28, 31, 62);
              padding-right: 5px;
              margin-right: 5px;
            }
          }
          button.edit-button{
            display: none;
          }
        }
        > div.buttons{
          height: 31px;
          width: 100%;
          justify-content: flex-end;
          margin-bottom: 6px;
        }
        .please-sign-in_and_simulated-review-button{
          margin-top: 10px;
          text-align: right;
        }
      }
    }

    .please-sign-in_and_simulated-review-button{
      width: 100%;
      justify-content: flex-end;
    }
    .title-and-buttons .category_and_author{
      height: auto !important;
    }
    .course-label{
      display: none;
    }
  }

  @media(max-width: 500px){
    section.course-description-and-stats{
      > div.container{
        flex-wrap: wrap;
        > div.course-description:not(.-empty){
          margin-bottom: 20px;
        }
        .course-description .ql-editor{
          text-align: right;
        }

        div.course-description{
          .placeholder{ display: none; }
        }
        ul.course-stats{
          width: 100%;
          li{ width: 100% !important; }
        }
      }
    }

    .course-title_and_category_and_author{
      button.invite-coauthor-button{ display: none; }
    }
  }
}
