@import 'css/variables';

:local(.section){
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  > .standard-course-card{
    margin: 0 10px;
    margin-bottom: 40px;
  }

  > .layout-div{
    width: 199px;
    margin: 0 10px;
    margin-bottom: 40px;
  }
}
