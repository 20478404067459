@import 'css/variables';

:local(.li){
  display: flex;
  padding: 5px 5px;
  padding-left: 0;
  border-bottom: 1px solid rgba(135, 128, 190, 0.15);
  cursor: default;
  a{
    cursor: pointer;
    color: $purple;
    &:hover{
      text-decoration: underline;
    }
  }

  &:last-child{
    border-bottom: none;
  }

  .text-orange{
    color: rgb(248, 125, 60);
  }

  // styles for particular types
  &.-type-someone_started_learning_your_course{
    > div.title_and_content_and_created-at{
      > div.content{
        span.learner-username{
          color: white;
        }
      }
    }
  }

  &.-type-someone_rated_your_course{
    > div.title_and_content_and_created-at{
      > div.content{
        span.rater-username{
          color: white;
        }
      }
    }
  }

  // style for read vs not-read notifications
  &.-already-read{
    // background: rgb(32, 29, 56);
    > button.mark-as-read-on-unread-button{
      cursor: pointer;
      > div.circle{
        background: rgb(48, 49, 90);
      }
    }
  }
  &.-not-yet-read{
    background: rgb(39, 43, 86);
    opacity: 1;
    > button.mark-as-read-on-unread-button{
      cursor: pointer;
      > div.circle{
        background: $orange;
      }
    }
  }

  > div.icon{
    flex-grow: 0; flex-shrink: 0;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div.title_and_content_and_created-at{
    flex-grow: 1; flex-shrink: 1;
    > div.title{
      font-size: 12px;
      color: rgb(78, 215, 76);
    }
    > div.content{
      font-size: 13px;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    > div.created-at{
      font-size: 11px;
      color: rgb(106, 126, 171);
    }
  }
  > button.mark-as-read-on-unread-button{
    flex-grow: 0; flex-shrink: 0;
    padding-left: 20px;
    padding-right: 20px;
    &:hover{
      opacity: 0.8;
    }
    > div.circle{
      $radius: 7px;
      width: $radius; height: $radius;
      border-radius: 50%;
    }
  }
}
