@import 'css/variables';

:local(.main){
  section.for-beginners{
    text-align: center;
    article.welcome{
      > h1.title{
        margin-top: 40px;
        font-size: 40px;
        color: $purple;
      }
      > div.description{
        h2{
          margin-top: 30px;
          margin-bottom: 30px;
        }
        a{ color: $purple; }
      }
    }
    hr{
      margin: 40px auto;
      border: none;
      border-bottom: 1px solid rgba(94, 140, 245, 0.07);
      max-width: 551px;
    }
  }

  > .loading.-request{
    margin-top: 100px;
  }
  > .container.standard-navigation_and_courses{
    > div.right{
      > section.list-of-courses{
        // margin-top: 70px;
      }
    }
  }

  @media(max-width: 430px) {
    .sort-by-dropdown-wrapper{
      display: none;
    }
    .list-of-courses{
      margin-top: 20px !important;
    }
  }
}
