:local(.section){
  > section.congratulations{
    text-align: center;
    // margin-top: 30px;
    h2{
      font-weight: 100;
      font-size: 37px;
      letter-spacing: 0.4px;
      color: rgb(81, 229, 190);
    }
    div.next-review-time{
      color: rgba(181, 213, 243, 1);
      font-size: 14px;
      margin-top: 13px;
      letter-spacing: 0.4px;

      text-transform: uppercase;
      margin-top: 20px;
      font-size: 12px;

      height: 41px;

      i{
        margin-right: 5px;
        font-size: 21px;
        width: 100%;
        margin-bottom: 6px;
        color: rgb(33, 165, 130);
      }
    }
  }
  section.whats-next{
    > h2{
      margin: 0 auto;
      margin-top: 40px;
      text-align: center;
      color: rgb(181, 213, 243);
      font-weight: 100;
      font-size: 25px;
    }
    > div.offered-courses{
      max-width: 915px;
      margin: 0 auto;
      margin-top: 34px;

      display: grid;
      // Must be the width of the card to center well.
      grid-template-columns: repeat(auto-fill, 199px);
      column-gap: 15px;
      justify-content: space-between;
      .standard-course-card{
        margin-bottom: 40px;
      }
      .layout-div{ display: none; }
    }
  }

  .social-buttons{
    display: flex;
    // width: 597px;
    max-width: 386px;
    margin: 0 auto;
    justify-content: space-around;
    margin-top: 33px;
    .social.button{
      text-transform: none;
      min-width: 180px;
      background: #21a582d1;
      color: white;
      padding: 8px 10px;
      // transition: background 300ms ease-in-out;
      // &:hover{
      //   background: #1cbb91f0; //#23b08bcc;
      // }

      svg, i{
        margin-right: 6px;
      }
      .text{
        font-size: 13px;
        letter-spacing: initial;
      }
      &.twitter{
        i{
          font-size: 18px;
        }
      }
      &.github{
        i{
          font-size: 20px;
          margin-top: -3px;
        }
      }
      &.patreon{
        svg{
          width: 18px;
          height: 18px;
        }
      }
    }
  }

}

:local(.section){
  @media(max-width: 400px) {
    section.congratulations h2{
      font-size: 28px;
    }

    .social-buttons{
      flex-wrap: wrap;
      .patreon{
        margin-top: 20px;
      }
    }
  }
}
