@import 'css/variables';
:local(main.main){
  > .loading.-request{
    margin-top: 100px;
  }
  > .container.standard-navigation_and_courses{
    .search-bar{
      // position
      height: 38px;
      align-self: center;
      margin-right: 10px;

      position: relative;
      i{
        position: absolute;
        top: 9px;
        left: 11px;
        font-size: 19px;
        color: white;
      }
      input{
        width: 100%;
        height: 100%;
        padding-left: 37px;
      }

      // looks
      font-size: 13px;
      border-radius: 2px;
      input{
        background: transparent;
        color: white;
        transition-duration: 200ms;
        transition-property: background;
        font-family: 'Open Sans';
        &::placeholder{
          color: white;
          font-family: 'Open Sans';
        }
        border-radius: 4px;
        background: #e2e4f008;
        &:focus, &:hover{
          background: #e2e4f008;
        }
      }
    }
    .left{

      .course-categories-nav{
        margin-top: 20px;
      }
    }

    .right{
      > ul.pagination{
        justify-content: flex-end;
        margin-top: 20px;
        > li:last-child{
          margin-right: 0;
        }
      }

      > section.list-of-courses{
        margin-top: 20px;

        opacity: 1;
        transition-property: opacity;
        transition-duration: 0.3s;
        &.-loading{
          opacity: 0.5;
        }
      }
    }
  }

  .search-bar.for-mobile{
    margin-right: 0 !important;
    margin-top: 20px !important;
  }
  .pagination.for-mobile{
    padding-bottom: 20px;
  }

  @media(min-width: 900px){
    .for-mobile{ display: none; }
  }
  @media(max-width: 900px){
    .for-desktop{ display: none; }
  }
}

