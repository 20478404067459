$purple: rgb(170, 160, 247);
// $purple: rgb(160, 187, 247);
$blue: rgb(21, 23, 58);
$orange: rgba(250, 100, 0, 0.84);

$green: rgb(56, 190, 155);

$body-font: 'Roboto';
$heading-font: 'Roboto';

$color-main-1: rgb(15, 17, 34); // header
$color-main-2: rgb(15, 17, 34); // bg
$color-main-3: rgb(24, 27, 57); // course
$faint-blue: rgb(53, 65, 105);
$not-so-faint-blue: rgb(90, 102, 141);

// old colors
$whale-tail-blue: rgb(236, 96, 100);
$whale-green: rgb(100, 215, 96);
$whale-red: rgb(253, 95, 108);
$whale-yellow: rgb(245, 205, 66);
$deep-green: rgb(1, 23, 4);


$course-in-list-height-desktop: 195px;
$course-in-list-height-mobile: 104px;


// blue he offered for learn
// $learn-button-color: rgb(31, 27, 134);

$learn-button-color: rgb(33, 165, 130);
$review-button-color: rgb(212, 85, 18);
$test-drive-button-color: rgb(236, 236, 133);

// $learn-button-color: rgb(8, 87, 185);
// $review-button-color: $purple;

// .button.-to-learn.-disabled, .button.-to-review.-disabled{
//   display: none;
// }

$body-color: white;
