.loading{
  &.-request{
    text-align: center;
    img{
      padding: 20px;
      width: 80px;
    }
  }
  &.-failure{
    padding: 20px;

    max-width: 600px;
    margin: 0 auto;
    margin-top: 30px;

    border-radius: 2px;
    text-align: center;
    // This is a privacy color, maybe we should differentiate between different error colors.
    background: rgb(48, 21, 54);
    color: rgb(236, 76, 123);
    font-size: 14px;
  }
}
