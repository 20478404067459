.react-select-container{
  *{
    cursor: pointer !important;
  }

  .react-select__control{
    border: none;
    box-shadow: none;
  }
}

.react-select{
  .react-select__control{
    background-color: rgb(21, 23, 55);
    border-color: rgb(21, 23, 55);
  }
  .react-select__control--is-focused, .react-select__control--menu-is-open, .react-select__control:hover{
    background-color: rgb(27, 30, 69);
    border-color: rgb(27, 30, 69);
    box-shadow: none;
  }
  .react-select__placeholder{
    color: white;
  }
  .react-select__single-value, .react-select__input{
    color: white;
  }
  .react-select__menu{
    background-color: rgb(21, 23, 55);
  }
  .react-select__option--is-focused{
    background-color: rgb(43, 47, 102);
    cursor: pointer;
  }
  .react-select__option--is-selected, .react-select__option:active{
    background-color: rgb(83, 89, 164);
  }
  .react-select__indicator-separator{
    background-color: rgb(84, 88, 147);
  }
  .react-select__indicator{
    color: rgb(84, 88, 147);
  }
}
