@import 'css/variables';

:local(.tab){
  .background.-blue{
    > section.import-flashcards, > section.export-flashcards{
      padding: 0 20px;
      > h2.title{
        font-size: 22px;
        text-align: center;
        font-weight: 100;
      }
      > article.instructions{
        margin-top: 26px;

        // color: rgb(199, 196, 196);
        font-size: 13px;

        b{
          font-weight: 300;
          color: $purple;
        }

        display: flex;
        > div.first-column{
          width: 50%;
          border-right: 1px solid rgba(223, 201, 247, 0.06);
          padding-right: 20px;

          p{
            margin: 0;
            margin-bottom: 6px;
            &:last-child{ margin-bottom: 0; }
          }
        }
        > div.second-column{
          width: 50%;
          padding-left: 20px;
          img{
            max-width: 400px;
            opacity: 0.96;
            &:hover{ opacity: 1; }    
          }
          figcaption{
            margin-bottom: 5px;          
          }
        }
      }
      > div.upload-excel-file-input-and-label{
        margin-top: 30px;
        > label{
          max-width: 300px;
          margin: 0 auto;

          border: 1px solid rgb(28, 38, 60);
          max-width: 300px;

          font-weight: bold;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;

          padding: 10px 20px;
          cursor: pointer;
          user-select: none;

          display: flex;
          justify-content: center;
          align-items: center;
          > i{
            padding-right: 20px;
          }
        }
      }
      > button.create-flashcards-button{
        margin: 0 auto;
        margin-top: 30px;
      }
    }
    > hr{
      margin-top: 30px;
      margin-bottom: 30px;
      border: none;
      border-bottom: 3px dashed rgba(223, 201, 247, 0.06);
    }
  }
}

