@import 'css/variables';

nav.standard-tab-navigation{
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;

  > button{
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.1px;

    margin-right: 50px;
    padding: 10px;
    color: white;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    &.-selected, &:hover{
      border-bottom: 1px solid $purple;
    }
  }
}
